<template>
  <div class="col-12">
    <div class="row">
      <div v-if="fastAssinaturaCarregando" class="col-12 text-center">
        <b-icon
          icon="gear-fill"
          animation="spin"
        /> Carregando dados
      </div>
      <div v-else class="col-12 p-4">
        <div v-if="!fastDocumentosAssinatura.documentoRevisao && !fastDocumentosAssinatura.finalizadoSucesso">
          <div class="row">
            <div v-if="id_matricula" class="col-sm-12 col-md-12 col-lg-6">
              <label>Selecionar o contrato</label>
              <div class="form-group">
                <select v-if="fastDocumentosAssinatura.templatesComposicao.length == 1"
                  v-model="fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template"
                  class="form-control"
                  readonly
                  >
                  <option v-for="tcomp in fastDocumentosAssinatura.templatesComposicao" :key="tcomp.id_assinatura_matricula_template" :value="tcomp.id_assinatura_matricula_template">
                    {{ tcomp.nome_template }}
                  </option>
                </select>
                <select v-else 
                  v-model="fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template"
                  class="form-control"
                  @change="getGatewayAssociadoComposicao(fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template), verificaSignatarios()"  
                >
                  <option value="">
                    Nenhum
                  </option>
                  <option v-for="tcomp in fastDocumentosAssinatura.templatesComposicao" :key="tcomp.id_assinatura_matricula_template" :value="tcomp.id_assinatura_matricula_template">
                    {{ tcomp.nome_template }}
                  </option>
                </select>
              </div>
            </div>
            <div v-if="fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template" class="col-sm-12 col-md-12 col-lg-6">
              <label>Gateway de Assinatura</label>
              <div class="form-group" v-if="fastDocumentosAssinatura.verificaGateway">
                <select
                  v-model="fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway"
                  class="form-control"  
                  readonly     
                >
                  <option v-for="ga in fastDocumentosAssinatura.gatewaysAssinatura" :key="ga.id_assinatura_gateway" :value="ga.id_assinatura_gateway">
                    {{ ga.nome_gateway }} <span v-if="!ga.disponivel">(inativo)</span>
                  </option>
                </select>
              </div>
              <div class="form-group" v-else>
                <select
                  v-model="fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway"
                  class="form-control" 
                >
                  <option value="">
                    Selecione
                  </option>
                  <option v-for="ga in fastDocumentosAssinatura.gatewaysAssinatura" :key="ga.id_assinatura_gateway" :value="ga.id_assinatura_gateway">
                    {{ ga.nome_gateway }} <span v-if="!ga.disponivel">(inativo)</span>
                  </option>
                </select>
              </div>
            </div>
            <div v-if="fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template" class="col-sm-12 col-md-12 col-lg-12">
              <label>Adicionar outros documentos</label>
              <div class="form-group">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                  <uploader
                    :file-status-text="statusText"
                    :options="optionsArquivoMatricula"
                    class="uploader-example"
                    @file-success="fileSuccess"
                    @file-added="fileValidation"
                  >
                    <uploader-unsupport />
                    <uploader-drop>
                      <p class="aluno_font_color text-center">
                        Arraste um ou mais documentos .PDF aqui ou
                      </p>
                      <div class="d-flex justify-content-center">
                        <uploader-btn
                          :attrs="restrictArquivo"
                          class="text-center"
                        >
                          Clique aqui para selecionar um ou mais .PDFs
                        </uploader-btn>
                      </div>
                    </uploader-drop>
                    <uploader-list />
                  </uploader>
                </div>
              </div>
            </div>
            <div v-if="fastDocumentosAssinatura.docs.length" class="col-sm-12 col-md-12 col-lg-12">
              <div v-if="fastAssinaturaCarregando" class="col-12 text-center">
                <b-icon
                  icon="gear-fill"
                  animation="spin"
                /> Carregando dados
              </div>
              <div v-else class="col-12 table-responsive p-4">
                <table class="table table-sm table-striped">
                  <thead class="thead-dark">
                    <tr>
                      <th>
                        ID
                      </th>
                      <th>
                        Link
                      </th>
                      <th>
                        Ações
                      </th>
                    </tr>
                  </thead>
                  <tbody v-if="id_matricula">
                    <tr v-for="d in fastDocumentosAssinatura.docs" :key="d.docIndex">
                      <td>
                        {{ d.docIndex }}
                      </td>
                      <td>
                        <a
                          :href="ajustaLinkApiAntiga(d.docURL)"
                          target="_blank"
                          download
                        >{{ d.docURL }}</a>
                      </td>
                      <td>
                        <button
                          class="btn-novo btn-sm btn-danger pt-0 pb-0 mr-2"
                          @click.prevent="
                            excluirDocumento(d.docIndex)
                          "
                        >
                          <small
                            class="text-white"
                          >Excluir</small>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="fastAssinaturaCarregando" class="col-12 text-center">
            <b-icon
              icon="gear-fill"
              animation="spin"
            /> Carregando dados
          </div>
          <div v-else-if="id_matricula && fastDocumentosAssinatura.mostraSignatarios" class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-2 d-flex justify-content-between">
              <h6>Signatários</h6>
            </div>
            <div v-if="fastDocumentosAssinatura.listaSignatarios.length" class="col-sm-12 col-md-12 col-lg-12">
              <div class="col-12 table-responsive p-4">
                <table class="table table-sm">
                  <thead class="thead-dark">
                    <tr>
                      <th>
                        CPF
                      </th>
                      <th>
                        Função
                      </th>
                      <th>
                        Tipo de envio
                      </th>
                      <th>
                        Contato
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(s, index) in fastDocumentosAssinatura.listaSignatarios" :key="index">
                      <td >
                        {{ s.cpf }}
                      </td>
                      <td>
                        <span v-if="s.aluno">
                          Aluno
                        </span>
                        <span v-else-if="s.responsavel_aluno">
                          Responsável pelo aluno
                        </span>
                        <span v-else-if="s.responsavel_gateway">
                          Responsável pelo gateway de assinaturas
                        </span>
                        <span v-else>
                          Não especificado
                        </span>
                      </td>
                      <td>
                        <select
                          v-model="s.tipoEnvioSignatarioMatricula"
                          class="form-control-sm"
                        >
                          <option value="">
                            Selecione
                          </option>
                          <option v-if="s.email" value="1">
                            Email
                          </option>
                          <option v-if="s.ddi && s.telefone" value="2">
                            SMS
                          </option>
                          <option v-if="s.ddi && s.telefone" value="3">
                            Whatsapp
                          </option>
                        </select>
                      </td>
                      <td class="d-flex" v-if="s.tipoEnvioSignatarioMatricula === '1'">
                        <input class="form-control" type="text" v-model="s.email">
                      </td>
                      <td class="d-flex" v-else-if="s.tipoEnvioSignatarioMatricula === '2'">
                        <span class="align-self-center">+{{ s.ddi }}</span> <input class="form-control form-control-tel mx-2" type="text" v-model="s.telefone"> <span class="align-self-center">(SMS)</span>
                      </td>
                      <td class="d-flex" v-else-if="s.tipoEnvioSignatarioMatricula === '3'">
                        <span class="align-self-center">+{{ s.ddi }}</span> <input class="form-control form-control-tel mx-2" type="text" v-model="s.telefone"> <span class="align-self-center">(WApp)</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center">
                <a v-if="id_matricula && !fastDocumentosAssinatura.redirecionaParaCorrecao" class="btn-novo btn-info" href="#" @click.prevent="validaGerarDoc()"><small class="text-white">Gerar documento</small></a>
                <a v-else-if="fastDocumentosAssinatura.redirecionaParaCorrecao" class="btn-novo btn-danger" href="#" @click.prevent="redirecionaParaCorrecaoUsuario()"><small class="text-white">Editar dados da pessoa #{{ fastDocumentosAssinatura.redirecionaParaCorrecao }}</small></a>
              </div>
            </div>
          </div>
        </div>
        <div v-if="fastDocumentosAssinatura.documentoRevisao && id_matricula"  class="w-100">
          <div class="text-center">
            <a class="btn-novo btn-primary m-2 text-white" @click="fastDocumentosAssinatura.documentoRevisao = false">Editar envio</a>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 text-center m-2">
            <h5>Por favor, revise o documento e, estando de acordo, finalize no botão ao final da página</h5>
          </div>
          <div class="d-flex justify-content-center w-100">
            <object
            :data="fastDocumentosAssinatura.documentoRevisao"
            type="application/pdf"
            class="w-100"
            style="min-height: 40rem;"
            >
              <embed
                :src="fastDocumentosAssinatura.documentoRevisao"
                type="application/pdf"
              >
            </object>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 text-center m-2">
            <a class="btn-novo btn-success blink_me text-white" href="#" @click.prevent="postGerarLink()">Finalizar</a>
          </div>
        </div>
        <div v-if="fastDocumentosAssinatura.finalizadoSucesso" class="col-12 text-center m-5">
          <h6 class="text-success">Link enviado com sucesso, geração de contrato finalizada.</h6>
        </div>
      </div>
    </div>
    <!-- modals -->
  </div>
</template>
<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
    name: "FastCriaDocumentos",
    components: {
    },
    props: {
        id_matricula: {
          type: Number,
          required: false,
        },
        id_pessoa: {
          type: Number,
          required: false,
        },
        id_plataforma: {
          type: Number,
          required: true,
        },
        id_secretaria_curso: {
          type: Number,
          required: false,
        }
    },
    
    mixins: [methods],
    data: function () {
      return {
        modalWidthSm: this.isMobile() ? "60%" : "50%",
        modalWidthMd: this.isMobile() ? "90%" : "50%",
        modalWidthLg: this.isMobile() ? "100%" : "90%",
        // Variáveis de documento
        fastAssinaturaCarregando: true,
        fastDocumentosAssinatura: {
          docIndex: 1,
          docURL: "",
          docs: [],
          gatewaysAssinatura: [],
          templatesComposicao: [],
          listaSignatarios: [],
          mostraSignatarios: '',
          tipoEnvioSignatarioMatricula: '',
          verificaGateway: '',
          redirecionaParaCorrecao: false,
          dadosParaPost: {  confirmar_geracao: false,
                            id_plataforma: "",
                            id_matricula: "",
                            id_assinatura_gateway: "",
                            id_assinatura_matricula_template: "",
                            documento_nome: "",
                            id_secretaria_inscricao: "",
                            id_contrato: "",
                            document_url: "",
                            id_assinatura_plataforma_documento_categoria: "",
                            document_url: "",
                            url_pdfs_merge: [],
                            signers: []
          },
          documentoRevisao: '',
          finalizadoSucesso: '',
        },
        // Upload arquivo
        optionsArquivoMatricula: {
          target: settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload_tmp",
          testChunks: false,
          chunkSize: 1024 * 1024 * 100, // 100MB
          singleFile: false,
          query: {
            id_plataforma: this.$route.params.id_plataforma
          },
          method: "POST",
          headers: { Authorization: "Bearer " + this.retornaToken() },
          categoryMap: {
            document: ["pdf"],
          },
          parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
            return parsedTimeRemaining
              .replace(/\syears?/, " anos")
              .replace(/\days?/, " dias")
              .replace(/\shours?/, " horas")
              .replace(/\sminutes?/, " minutos")
              .replace(/\sseconds?/, " segundos");
          },
        },
        optionsArquivoGeral: {
          target: settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload_tmp",
          testChunks: false,
          chunkSize: 1024 * 1024 * 100, // 100MB
          singleFile: true,
          query: {
            id_plataforma: this.$route.params.id_plataforma
          },
          method: "POST",
          headers: { Authorization: "Bearer " + this.retornaToken() },
          categoryMap: {
            document: ["pdf"],
          },
          parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
            return parsedTimeRemaining
              .replace(/\syears?/, " anos")
              .replace(/\days?/, " dias")
              .replace(/\shours?/, " horas")
              .replace(/\sminutes?/, " minutos")
              .replace(/\sseconds?/, " segundos");
          },
        },
        restrictArquivo: {
          accept: "application/pdf",
        },
        statusText: {
          success: "Sucesso",
          error: "Erro",
          uploading: "Fazendo upload...",
          paused: "Upload pausado",
          waiting: "Aguardando...",
        },
      }        
    },
    mounted: function () {   
      if (this.id_plataforma){
        this.fastAssinaturaCarregando = true;
        this.getGatewaysAssinatura();
        this.getTemplatesComposicao()
        this.fastAssinaturaCarregando = false
      } else {
        this.exibeToasty("O identificador da pessoa ou da plataforma não foram informados", "error")
        this.fastAssinaturaCarregando = false      
      }
    },
    computed: {
      organizaDDI(){
          const listaPaises = [...this.$store.state.fastPlataformaDadosPaises]
          return listaPaises.sort(this.reornedarArrayObjetos("iso3"))
      },
    },
    watch: {
      'fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template': 'verificaSignatarios',
      'fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway': 'verificaSignatarios'
    },
    methods: {
    getGatewaysAssinatura(){
      this.fastAssinaturaCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_gateway/lista_disponiveis", `id_plataforma=${this.$route.params.id_plataforma}`)
      .then((res) => {
        if (res) {
          this.fastDocumentosAssinatura.gatewaysAssinatura = res
        }
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        this.exibeToasty(e, "error")
        console.log(e)
        this.fastAssinaturaCarregando = false
      })
    },
    getSignatariosMatricula(){
      this.fastAssinaturaCarregando = true
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_signatarios", `id_plataforma=${this.$route.params.id_plataforma}&id_assinatura_matricula_template=${this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template}&id_assinatura_gateway=${this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway}&id_matricula=${this.id_matricula}`)
      .then(obj => {
        if (obj.success == false) {
          console.log("obj.error ", obj.error)
          console.log("obj.description ", obj.description)
          this.exibeToasty(obj.error + obj.description, 'error')
        } else if (obj.length) {
          obj.forEach(e => {
            const verifica_id_pessoa = this.fastDocumentosAssinatura.listaSignatarios.some(x => x.id_pessoa === e.id_pessoa)
            if (!verifica_id_pessoa){
              e.tipoEnvioSignatarioMatricula = ''
              this.fastDocumentosAssinatura.listaSignatarios.push(e)
            }
          });
        }
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        console.log(e);
        this.fastAssinaturaCarregando = false
      })
    },
    getTemplatesComposicao(){
      this.fastAssinaturaCarregando = true
      this.promiseGetFastApi("api/fast_assinatura_matricula_template/lista", `id_plataforma=${this.$route.params.id_plataforma}&ativo=true`)
      .then(obj => {
        if (obj.length){
          obj.forEach(e => {
            if (e.id_secretaria_curso === this.id_secretaria_curso) {
              this.fastDocumentosAssinatura.templatesComposicao.push(e)
            }
          })
          if (!this.fastDocumentosAssinatura.templatesComposicao.length) {
            this.fastDocumentosAssinatura.templatesComposicao = obj
          } else if (this.fastDocumentosAssinatura.templatesComposicao.length == 1) {
            this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template = this.fastDocumentosAssinatura.templatesComposicao[0].id_assinatura_matricula_template
            this.getGatewayAssociadoComposicao(this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template)
          }
        }
        this.fastAssinaturaCarregando = false
      }).catch(e => {
        console.log(e);
        this.fastAssinaturaCarregando = false
      })
    },
    getGatewayAssociadoComposicao(id_assinatura_matricula_template){
      if (id_assinatura_matricula_template) {
        const template = [...this.fastDocumentosAssinatura.templatesComposicao].find(t => t.id_assinatura_matricula_template === id_assinatura_matricula_template)
        this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway = template.id_assinatura_gateway
        if (template.id_assinatura_gateway){
          this.fastDocumentosAssinatura.verificaGateway = true
        } else {
          this.fastDocumentosAssinatura.verificaGateway = false
        }
          return template.id_assinatura_gateway ? template.id_assinatura_gateway : '';
      }
    },
    async postGerarDocumento(){
      this.fastAssinaturaCarregando = true
      if (this.fastDocumentosAssinatura.docs.length){
        this.fastDocumentosAssinatura.docs.forEach(e => {
          this.fastDocumentosAssinatura.dadosParaPost.url_pdfs_merge.push(e.docURL)
        })
      }
      if (this.id_matricula) {
        let obj = {
          confirmar_geracao: false,
          id_plataforma: this.$route.params.id_plataforma,
          id_matricula: this.id_matricula,
          id_assinatura_matricula_template: this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template,
          id_assinatura_gateway: this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway,
          url_pdfs_merge: this.fastDocumentosAssinatura.dadosParaPost.url_pdfs_merge,
          signers: this.fastDocumentosAssinatura.dadosParaPost.signers,
        }
        return new Promise(async (resolve, reject) => {
          try {
            let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_matricula_aluno/gera_documento_assinatura_template", this.fastAjaxOptions("POST", JSON.stringify(obj)))
            let res = await resp.json();
            if (res.success == false) {
              console.log(res.error)
              console.log(res.description)
              this.exibeToasty(res.error + " " + res.description, "error")
            } else {
              console.log(res)
              this.fastDocumentosAssinatura.documentoRevisao = res.url_arquivo_original
              resolve(resp)
            }
            this.fastAssinaturaCarregando = false
          } catch (error) {
            console.log(error)
            this.exibeToasty(error, "error")
            reject(error)
            this.fastAssinaturaCarregando = false
          }
        })
      }
    },
    async postGerarLink(){
      this.fastAssinaturaCarregando = true
      let obj = {
        confirmar_geracao: true,
        id_plataforma: this.$route.params.id_plataforma,
        id_matricula: this.id_matricula,
        id_assinatura_matricula_template: this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template,
        id_assinatura_gateway: this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway,
        url_pdfs_merge: this.fastDocumentosAssinatura.dadosParaPost.url_pdfs_merge,
        signers: this.fastDocumentosAssinatura.dadosParaPost.signers,
      }
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(settings.endApiFastEad + "api/fast_secretaria_matricula_aluno/gera_documento_assinatura_template", this.fastAjaxOptions("POST", JSON.stringify(obj)))
          let res = await resp.json();
          if (res.success == false) {
            console.log(res.error)
            console.log(res.description)
            this.exibeToasty(res.error + " " + res.description, "error")
          } else {
            this.exibeToasty("Documento gerado com sucesso!", "success");
            this.$store.state.fastControlaComponentes.showHideConsultaDocumentoAuteticacao = true
            resolve(resp)
            this.limpaCampos();
          }
          this.fastAssinaturaCarregando = false
        } catch (error) {
          console.log(error)
          this.exibeToasty(error, "error")
          reject(error)
          this.fastAssinaturaCarregando = false
        }
      })
    },
    fileValidation(e) {
      this.exibeToasty("Documento carregado","success")
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        if (this.id_matricula) {
          this.fastDocURL = result.url;
          this.fastDocumentosAssinatura.docs.push({docIndex: this.fastDocumentosAssinatura.docIndex++, docURL: this.fastDocURL})
        } else {
          this.fastDocumentosAssinatura.dadosParaPost.document_url = result.url
        }
      } else {
        console.log("filesuccess: ocorreu um erro")
      }
    },
    verificaSignatarios(){
      if(this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template && this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway){
        this.mostraSignatariosMatricula()
      } else {
        this.fastDocumentosAssinatura.mostraSignatarios = false
      }
    },
    mostraSignatariosMatricula(){
      let erros = []
      if (!this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template) erros.push("O template de composição de documentos de matrícula deve estar selecionado")
      if (!this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway) erros.push("O gateway de assinatura deve estar selecionado")
      if (erros.length){
        erros.forEach(e => {
          this.exibeToasty(e, "error")
        })
      } else {
        this.fastDocumentosAssinatura.mostraSignatarios = true;
        this.getSignatariosMatricula();
      }
    },
    excluirDocumento(id_documento){
      const doc_deletar = this.fastDocumentosAssinatura.docs.find(doc => doc.docIndex === id_documento)
      if (doc_deletar) {
        const doc_index = this.fastDocumentosAssinatura.docs.indexOf(doc_deletar)
        this.fastDocumentosAssinatura.docs.splice(doc_index, 1)
      }
    },
    validaGerarDoc(){
      let erros = [];
      this.fastDocumentosAssinatura.dadosParaPost.signers = []
      if (!this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template) erros.push('Por favor, especifique o template de composição de documentos')
      if (!this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway) erros.push('Por favor, especifique o gateway de assinaturas')
      this.fastDocumentosAssinatura.listaSignatarios.forEach(e => {
        if (!e.tipoEnvioSignatarioMatricula) {
          erros.push('Por favor, preencha o tipo de envio para todos os signatários listados')
        } else {
            if (e.tipoEnvioSignatarioMatricula === '1') this.fastDocumentosAssinatura.dadosParaPost.signers.push({id_pessoa: e.id_pessoa, email: e.email})
            if (e.tipoEnvioSignatarioMatricula === '2') this.fastDocumentosAssinatura.dadosParaPost.signers.push({id_pessoa: e.id_pessoa, ddi: e.ddi, phone_sms: e.telefone})
            if (e.tipoEnvioSignatarioMatricula === '3') this.fastDocumentosAssinatura.dadosParaPost.signers.push({id_pessoa: e.id_pessoa, ddi: e.ddi, phone_whats: e.telefone})
        }
      })
      if (!erros.length){
        this.fastDocumentosAssinatura.listaSignatarios.forEach(e => {
          if(!e.cpf) {
            if (e.id_pessoa) this.fastDocumentosAssinatura.redirecionaParaCorrecao = e.id_pessoa;
          }
        })
      }
      if (erros.length){
        erros.forEach(e => {
          this.exibeToasty(e, "error")
        })
      } else {
        // console.log("dados para post ", JSON.stringify(this.fastDocumentosAssinatura.dadosParaPost))
        // console.log("lista signatários ", JSON.stringify(this.fastDocumentosAssinatura.listaSignatarios))
        this.postGerarDocumento()
      }
    },
    redirecionaParaCorrecaoUsuario (){
      window.location.href = `/plataforma/${this.id_plataforma}/secretaria/aluno/${this.fastDocumentosAssinatura.redirecionaParaCorrecao}`;
    },
    limpaCampos() {
      this.fastDocumentosAssinatura.docIndex = 1
      this.fastDocumentosAssinatura.docURL = ""
      this.fastDocumentosAssinatura.docs = []
      this.fastDocumentosAssinatura.tipoEnvio = ""
      this.fastDocumentosAssinatura.gatewaysAssinatura = []
      this.fastDocumentosAssinatura.dadosParaPost.confirmar_geracao = false
      this.fastDocumentosAssinatura.dadosParaPost.id_plataforma = ""
      this.fastDocumentosAssinatura.dadosParaPost.id_matricula = ""
      this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_gateway = ""
      this.fastDocumentosAssinatura.dadosParaPost.documento_nome = ""
      this.fastDocumentosAssinatura.dadosParaPost.id_secretaria_inscricao = ""
      this.fastDocumentosAssinatura.dadosParaPost.id_contrato = ""
      this.fastDocumentosAssinatura.dadosParaPost.url_pdfs_merge = []
      this.fastDocumentosAssinatura.dadosParaPost.signers = []
      this.fastDocumentosAssinatura.documentoRevisao = ""
      this.fastDocumentosAssinatura.dadosParaPost.id_assinatura_matricula_template = ""
      this.fastDocumentosAssinatura.finalizadoSucesso = true
    },
  }
}
</script>
<style scoped>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}
.sugestao-lista {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    border: 1px solid #d8d8d8;
    border-top: none;
    padding: 0;
    margin: 0;
    z-index: 999;
  }
  .sugestao-item {
    list-style-type: none;
    padding: 3px 6px;
    cursor: pointer;
    color: rgb(95, 95, 95);
  }
  .sugestao-item:hover {
    transition: 0.3s;
    background-color: #e8e8e8;
  }
  .form-control-tel {
    width: 10em;
  }

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>